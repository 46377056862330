import * as React from "react"
import LayoutPages from '../components/Layout/layoutPages';
import Seo from "../components/seo"
import { useSiteMetadata } from "../hooks/use-site-metadata"
import PuntaArena68Comp from '../components/PuntaArena68';

const PuntaArena68Page = (props) => {
  const { title, siteUrl } = useSiteMetadata();

  return (
    <LayoutPages pathname={props.location.pathname}>
      <Seo
        title={title}
        description="Seguridad y confianza en tu patrimonio"
        keywords='patrimonio, terrenos, bienes raices, inmobiliaria'
        lang={`es`}
        imageF='inmoax-punta-arena-68-f.jpg'
        imageT='inmoax-punta-arena-68-t.jpg'
        url={`${siteUrl}/`}
      />

      <PuntaArena68Comp {...props} />

    </LayoutPages>
  )
}

export default PuntaArena68Page
