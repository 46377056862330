import React from 'react';
import {GlobalStyle} from '../Home/styles';
import {PuntaArenaBackComp} from '../DesarrollosBack/PuntaArena68/PuntaArenaBack';
import ImgBack from '../../images/desarrollos/punta-arena-68/punta-arena-68-g.jpg';
import {DesarrollosDetallesPuntaArena68Comp} from '../DesarrollosDetalles/PuntaArena68/DesarrollosDetallesPuntaArena68';
import {PlanoPuntaArena68Comp} from '../Planos/PuntaArena68/Plano';
import {UbicacionComp} from '../Ubicacion/';

const title = 'Punta Arena 68';
const lugar = 'Se ubica a 10 min. de Puerto Escondido.';

const PuntaArena68Comp = () => {

  return (
    <div className="cont-page page-home">
      <GlobalStyle/>
      <PuntaArenaBackComp title={title} lugar={lugar} img={ImgBack}/>
      <DesarrollosDetallesPuntaArena68Comp/>
      <PlanoPuntaArena68Comp/>
      <UbicacionComp desarrollo={'puntaarena68'}/>
    </div>
  )
}

export default PuntaArena68Comp
